<template>
<div>
    <b-row>
        <b-col cols="12">
            <b-card class="tableMemoSubject m-0">
                <b-table 
                striped
                small
                id="tableMemoSubject"
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                :items="items"
                :fields="fields"
                @row-dblclicked="editForm"
                @row-clicked="clicked"
                head-row-variant="secondary"
                >
                <template #cell(checkbox)="row">
                    <b-form-checkbox
                    v-model="row.detailsShowing"
                    plain
                    class="vs-checkbox-con"
                    @change="row.toggleDetails"
                    >
                    </b-form-checkbox>
                </template>   
                <template #cell(is_active)="data">
                        {{data.item.is_active==true? 'Активный' : 'Неактивный'}}
                    </template>
            </b-table>
        </b-card>
    </b-col>
    </b-row>
</div>
    
</template>
<script>
import { resizeable } from "@/utils/TableFunctions/resizeable.js";
import { cellSelect } from "@/utils/TableFunctions/cellSelect";
export default {
    props: ['items'],
    data(){
        return {
            fields: [ 
                { key: 'checkbox', label: '', thStyle: {width: '20px'}},
                { key: 'id', label: 'Id', sortable: true, thStyle: {width: '40px'}},
                { key: 'name', label: 'Наименование', sortable: true, thStyle: {width: '150px'}},
                { key: 'is_active', label: 'Состояние', sortable: true, thStyle: {width: '150px'}},
            ],
        }
    },
    mounted(){
        resizeable()
    },
    methods:{
        editForm(id){
            this.$emit('editForm', id)
        },
        clicked(item, index, event){
            this.$store.state.filterPlus.tableIndex = index
            cellSelect()
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.tableMemoSubject .card-body{
    padding: 0px;
}
.tableMemoSubject{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .tableMemoSubject{
    overflow: auto; 
    height: 530px;
    }
}  
</style> 